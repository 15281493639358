<template>
  <div class="app-container" v-loading="loading">
    <el-header class="new-header">
      <div class="new-header-left">用户列表</div>
    </el-header>
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      @keyup.enter.native="onSubmit"
    >
      <el-form-item label="用户手机号">
        <el-input
          v-model="searchForm.phone"
          placeholder="用户手机号"
          size="small"
          clearable
          @clear="searchForm.phone = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户姓名:" prop="userName">
        <el-input
          v-model="searchForm.userName"
          placeholder="用户姓名"
          size="small"
          clearable
          @clear="searchForm.userName = null"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" icon="search" size="small"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      style="width: 100%"
      ref="list"
      stripe
      size="small"
    >
      <el-table-column
        prop="userName"
        label="用户姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="用户手机号"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
      ></el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" content="编辑">
            <el-button
              type="text"
              @click="handleInitModifyUser(scope.row)"
              size="small"
            >
              编辑
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageVo.current"
        :page-sizes="[2, 10, 20, 50, 100]"
        :page-size="pageVo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-dialog title="编辑用户" :visible.sync="dialogForModifyUser">
      <el-form
        ref="modifyForm"
        :model="modifyForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="手机号码:" prop="phone">
              <el-input
                v-model="modifyForm.phone"
                placeholder="手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户姓名:" prop="userName">
              <el-input
                v-model="modifyForm.userName"
                placeholder="请输入用户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password">
              <el-input
                v-model="modifyForm.password"
                placeholder="密码,如果不填,默认为手机号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForModifyUser = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleModifyUser">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { editUserOfMerchant, loadUserListOfMerchantByPage } from "../../api";

export default {
  name: "",
  data() {
    return {
      dialogForModifyUser: false,
      searchForm: {
        userId: this.$store.getters.userId,
        userName: null,
        phone: null,
      },
      modifyForm: {
        userId: "",
        userName: "",
        password: "",
        phone: "",
        manageUserId: this.$store.getters.userId,
      },
      list: [],
      pageVo: {
        current: 1,
        size: 10,
      },
      totalCount: 0,
      loading: false,
      rules: {
        phone: [
          { required: true, message: "请输入用户手机号码", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.loadUserList();
  },
  methods: {
    handleInitModifyUser(row) {
      Object.assign(this.modifyForm, row);
      this.modifyForm.password = null;
      this.dialogForModifyUser = true;
    },
    handleModifyUser() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          let param = {
            ...this.modifyForm,
          };
          editUserOfMerchant(param).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "编辑用户成功",
              });
              this.dialogForModifyUser = false;
              this.loadUserList();
            }
          });
        }
      });
    },
    onSubmit() {
      this.pageVo.current = 1;
      this.loadUserList();
    },
    handleSizeChange(val) {
      this.pageVo.size = val;
      this.loadUserList();
    },
    handleCurrentChange(val) {
      this.pageVo.current = val;
      this.loadUserList();
    },
    loadUserList() {
      let params = {
        ...this.pageVo,
        params: this.searchForm,
      };
      this.loading = true;
      loadUserListOfMerchantByPage(params).then((data) => {
        console.log(data);
        this.list = data.records;
        this.totalCount = data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss"></style>
